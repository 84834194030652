header {
    position: fixed;
    z-index: 10;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0 40px;
    top: 0;
    @include bgBackNone();
    @include breakpoint(768px, max) {
        padding: 20px;
    }
    .logo {
        @include breakpoint(768px, max) {
            position: absolute;
            z-index: 9999;
        }
    }
}