.titulo {
    display: table;
    width: 100%;
    background-image: url($img + 'veiculo/titulo.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    padding: 140px 0 0;
    margin-bottom: 80px;
    .mapa {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
    h1 {
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }
    .breadcumb {
        color: #ffffff;
        text-align: center;
    }
    .mascara {
        position: relative;
        z-index: 2;
        display: table;
        width: 100%;
        height: 76px;
        bottom: 0;
        background-image: url($img + 'vitrine/borda.png');
        background-repeat: no-repeat;
        background-position: center top;
        margin-top: 60px;
    }
}