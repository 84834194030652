a {
    color: $vermelho;
}

.hiddenText {
    text-indent: 1000%;
    white-space: nowrap;
    overflow: hidden;
}

.mb7 {
    margin-bottom: 7px
}

.mb10 {
    margin-bottom: 10px
}

.mb15 {
    margin-bottom: 15px
}

.mb20 {
    margin-bottom: 20px
}

.mb30 {
    margin-bottom: 30px
}

.mb40 {
    margin-bottom: 40px
}

.mb50 {
    margin-bottom: 50px
}

.pr10 {
    padding-right: 10px;
}
.pr20 {
    padding-right: 20px;
}
.pr30 {
    padding-right: 30px;
}
.pr40 {
    padding-right: 40px;
}
.pr50 {
    padding-right: 50px;
}

.noShow {
    display: none;
}

.container {
    width: 100%;
    max-width: $containerWidth;
}

.error {
    color: $error;
    display: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
    &.show {
        display: block;
    }
}

.errorField {
    border: 1px solid $error !important;
    background-color: #F9F1F1 !important;
}

select::-ms-expand {
    display: none;
}

.modal {
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    background-color: rgba(238,238,238, 0.90);
    width: 100%;
    height: 100%;
    top: 0;
    display: none;
    .containerModal {
        width: 100%;
        max-width: 520px;
        position: relative;
        margin: auto;
        background-color : #FFFFFF;
        padding : 30px;
        text-align : left;
        .close {
            position: absolute;
            right: -15px;
            top : -15px;
            width: 32px;
            height: 32px;
            opacity: 1;
            background-color : $vermelho;
            border-radius: 5px;
            color: #FFF;
            padding : 4px 8px;
            i {
                font-size : 22px;
            }
            &:hover {
                background-color : #000;
                cursor: pointer;
            }
        }
        .dadosConteudo {
            text-align: justify;
            padding-right: 100px;
            h1 {
                color: #fec593;
                margin: 0 0 40px 0;
                letter-spacing: 0;
                font-weight: 700;
                text-transform: none;
            }
            p {
                color: #505766;
                font-size: 18px;
                line-height: 25px;
                font-style: italic;
            }
            .msgCadastro {
                font-weight: 700;
                font-size: 18px;
                color: #505766;
                position: relative;
                width: 100%;
                padding: 40px 0;
            }
        }
    }
    @include breakpoint('768px', 'max') {
        .containerModal {
            height: 100vh;
            overflow-y: auto;
            width: 100vw;
            padding: 30px;
            display: block;
            .close {
                top: 20px;
                right: 20px;
            }
            .dadosConteudo {
                padding-right: 0;
                h1 {
                    margin-bottom: 20px;
                }
            }
            p.atencao {
                font-size: 30px;
                line-height: normal;
                color: $vermelho;
            }
            legend {
                line-height: normal;
                padding-bottom: 10px;
            }
        }
    }
}

.showModal {
    [class^='modal'] {
        display: block !important;
    }
}

form {
    legend {
        text-transform: uppercase;
        border-bottom: #c1c1c1 1px solid;
        padding-bottom: 8px;
        margin-bottom: 20px;
        margin-top: 50px;
    }
}