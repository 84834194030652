.redes {
    display: table;
    width: 100%;
    background-image: url($img + 'footer/back-redes.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 80px 0 60px;
    margin: 40px 0 0;
    h1 {
        text-transform: none;
        color: #ffffff;
        text-align: center;
        margin-bottom: 40px;
    }
    .col-md-4 {
        margin-bottom: 25px;
    }
    .instaFoto {
        margin-bottom: 10px;
    }
}