.fancybox-overlay {
    background: url('assets/img/fancybox_overlay.png');
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url('assets/img/fancybox_sprite.png');
}

#fancybox-loading div {
	background: url('assets/img/fancybox_loading.gif') center center no-repeat;
}

.fancybox-nav {
	background: transparent url('assets/img/blank.gif');
}