.veiculo {
    position: relative;
    margin: 0 0 40px;
    z-index: 1;
    .subtitulo {
        background-color: #ffffff;
        padding: 15px 30px;
        position: relative;
        margin-bottom: 20px;
        h1 {
            color: #000;
            position: relative;
            z-index: 3;
            font-weight: 600;
            font-size: 32px;
            padding: 0;
            margin: 0;
        }
        &:after {
            content: '';
            background-color: $azul;
            width: 5px;
            height: 70%;
            position: absolute;
            z-index: 2;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .foto {
        margin-bottom: 8px;
        a {
            img {
                display: block;
                width: 100%;
            }
        }
        .carouselVeiculo {
            .owl-thumb-item img {
                width: 150px;
                height: auto;
            }
        
            .owl-thumbs {
                position: absolute;
                bottom: 0;
                left: 0;
                display: table;
                width: 100%;
                text-align: center;
                padding: 5%;
            }
        
            .owl-thumb-item {
                display: table-cell;
                border: none;
                background: none;
                padding: 0;
                opacity: .4;
            }
        
            .owl-thumb-item.active {
                opacity: 1;
            }
            .owl-nav {
                position: absolute;
                width: 100%;
                top: 45%;
                button {
                    position: absolute;
                    display: table;
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    span {
                        display: none;
                    }
                    &.owl-prev {
                        background-image: url($img + 'vitrine/seta-left.svg');
                        left: 30px;
                    }
                    &.owl-next {
                        background-image: url($img + 'vitrine/seta-right.svg');
                        right: 30px;
                    }
                }
            }
        }
    }
    .miniFotos {
        padding: 0 11px;
        .col-md-2 {
            padding: 0 4px;
            margin-bottom: 8px;
        }
    }
    .descricao {
        background-color: #fff;
        border: #E1E1E1 1px solid;
        box-shadow: none;
        font-family: $fontDefault;
        font-size: 16px;
        color: $azul;
        font-weight: 400;
        padding: 24px;
        color: #858585;
        font-size: 14px;
        line-height: 20px;
        margin: 20px 0;
        text-transform: uppercase;
        @include breakpoint(768px, max) {
            word-break: break-all;
        }
    }
    .detalhes {
        background-color: #fff;
        padding: 30px;
        h3 {
            text-transform: uppercase;
            font-weight: 600;
            position: relative;
            font-size: 18px;
            margin-bottom: 20px;
            &:after {
                content: '';
                background-color: $azul;
                width: 4px;
                height: 100%;
                position: absolute;
                z-index: 2;
                left: -30px;
                top: 49%;
                transform: translate(-50%, -50%);
            }
        }
        .table {
            tr {
                td {
                    text-transform: uppercase;
                    &.campo {
                        font-weight: bold;
                    }
                }
            }
        }
        .preco {
            background-color: #e1e1e1;
            color: $azul;
            text-align: center;
            padding: 10px 20px;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 10px;
        }
        .whatsapp {
            display: table;
            width: 100%;
            height: auto;
            background-color: #2a5f55;
            padding: 12px 20px;
            color: #ffffff;
            text-align: center;
            margin-bottom: 30px;
            a {
                color: #ffffff;
                .icone {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 6px;
                    i {
                        font-size: 40px;
                        margin-top: 3px;
                    }
                }
                .text {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 73%;
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 600;
                }
                &.desktop {
                    display: block;
                    @include breakpoint(768px, max) {
                        display: none;
                    }
                }
                &.mobile {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                }
            }
        }
        .tags {
            margin-bottom: 30px;
            .tag {
                position: relative;
                padding-left: 28px;
                text-transform: uppercase;
                margin-bottom: 4px;
                &:before {
                    position: absolute;
                    content: '';
                    background-image: url($img + 'veiculo/check.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    z-index: 1;
                    width: 10px;
                    height: 15px;
                    left: 15px;
                }
            }
        }
        form {
        }
    }
}