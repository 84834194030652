.carroIdeal {
    display: block;
    margin: 40px 0;
    h1 {
        text-align: center;
        margin-bottom: 30px;
    }
    form {
        margin-bottom: 30px;
    }
    .marcas {
        margin-top: 40px;
        a {
            .marca {
                background-color: #ffffff;
                display: block;
                min-height: 185px;
                border: #E1E1E1 1px solid;
                padding: 30px 30px 20px;
                @include breakpoint(768px, max) {
                    margin-bottom: 25px;
                }
                .logo {
                    display: flex;
                    min-height: 98px;
                    margin-bottom: 25px;
                }
                .nome {
                    color: #4A4A4A;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
            &:hover {
                text-decoration: none;
                .marca {
                    border-color: $azul;
                    .nome {
                        color: $azul;
                    }
                }
            }
        }
    }
}