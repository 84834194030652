.banners {
    .banner {
        display: flex;
        background-color: $vermelho;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        padding: 24px;
        align-items: center;
        text-transform: uppercase;
        min-height: 120px;
        color: #ffffff;
        margin-bottom: 25px;
    }
}