.form-control {
    font-family: $fontDefault;
    border-radius: 0;
    background-color: #fff;
    border: #e1e1e1 1px solid;
    box-shadow: none;
    font-family: $fontDefault;
    font-size: 16px;
    color: $azul;
    font-weight: 400;
    padding: 16px 24px;
    height: 52px;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child) {
    border-radius: 0;
}
