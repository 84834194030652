.sobre {
    .home {
        display: table;
        width: 100%;
        height: auto;
        background-image: url($img + 'geral/sobre.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        padding: 120px 0;
        margin: 60px 0;
        background-size: cover;
        h1 {
            color: #ffffff;
            text-align: center;
        }
        .chamada {
            color: #ffffff;
            text-align: center;
        }
        .fatos {
            max-width: 854px;
            margin: 40px auto;
            color: #ffffff;
            .col-md-4 {
                padding: 0;
            }
            .fato {
                background-color: rgba(#000000, 0.5);
                min-height: 120px;
                display: flex;
                align-items: center;
                padding: 30px;
                margin-bottom: 25px;
                @include breakpoint(768px, max) {
                    margin: 0;
                }
                &.left {
                    -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
                    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
                    @include breakpoint(768px, max) {
                        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                    }
                }
                &.center {
                    -webkit-clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
                    clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
                    padding: 30px 30px 30px 60px;
                    @include breakpoint(768px, max) {
                        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                    }
                }
                &.right {
                    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
                    clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
                    padding: 30px 30px 30px 55px;
                    @include breakpoint(768px, max) {
                        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                    }
                }
                .dados {
                    display: flex;
                    align-items: center;
                    @include breakpoint(768px, max) {
                        margin: auto;
                    }
                    .icone {
                        display: inline-block;
                        max-width: 92px;
                        margin-right: 20px;
                    }
                    .info {
                        display: inline-block;
                        max-width: 115px;
                        .numero {
                            font-weight: 600;
                            text-transform: uppercase;
                            font-size: 26px;
                            line-height: 32px;
                        }
                        .texto {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        .botao {
            a {
                display: table;
                margin: auto;
                padding-right: 40px;
                padding-left: 40px;
                &.btn-red {
                    -webkit-clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
                    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
                }
            }
        }
    }
}