.vitrine {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    .carousel {
		.item {
			position: relative;
			width: 100vw;
            height: 685px;
            background-position: center center;
            background-size: cover;
			@include breakpoint(768px, max) {
				height: calc( 100vh - 66px);
			}
			.dados {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: auto;
                bottom: 115px;
                a {
                    display: table;
                    width: 100%;
                    max-width: 475px;
                    height: auto;
                    .novidade {
                        // @include paragonBorder();
                        display: table;
                        width: auto;
                        height: auto;
                        background-color: $azul;
                        color: #ffffff;
                        text-transform: uppercase;
                        font-size: 20px;
                        font-weight: 300;
                        padding: 10px 30px;
                    }
                    .nome {
                        color: #ffffff;
                        font-family: $fontDefault;
                        font-weight: 600;
                        font-size: 50px;
                        line-height: 60px;
                        text-shadow: 1px 1px 10px #000;
                        @include breakpoint(768px, max) {
                            font-size: 36px;
                            line-height: 44px;
                        }
                    }
                    .descricao {
                        color: #ffffff;
                        font-family: $fontDefault;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 30px;
                        text-shadow: 1px 1px 10px #000;
                        @include breakpoint(768px, max) {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                    .preco {
                        color: #ffffff;
                        font-family: $fontDefault;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 44px;
                        margin-top: 10px;
                        text-shadow: 1px 1px 10px #000;
                        .link {
                            @include paralelograma();
                            background-color: #ff0000;
                            display: inline-block;
                            width: auto;
                            height: auto;
                            margin-left: 15px;
                            padding: 0 20px;
                            text-shadow: none;
                            transition: all .3s ease;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        .preco {
                            .link {
                                background-color: $azul;
                            }
                        }
                    }
                }
            }
        }
        .owl-nav {
            position: absolute;
            width: 100%;
            top: 45%;
            @include breakpoint(768px, max) {
                top: auto;
                bottom: 80px;
            }
            button {
                position: absolute;
                display: table;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center center;
                span {
                    display: none;
                }
                &.owl-prev {
                    background-image: url($img + 'vitrine/seta-left.svg');
                    left: 30px;
                    @include breakpoint(768px, max) {
                        left: 65vw;
                    }
                }
                &.owl-next {
                    background-image: url($img + 'vitrine/seta-right.svg');
                    right: 30px;
                }
            }
        }
        .owl-dots {
            text-align: center;
            margin: 30px 0;
            button {
                &.owl-dot {
                    border: #FFF 2px solid;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    margin: 10px;
                    &.active {
                        border-color: $azul;
                        background-color: $azul;
                    }
                }
            }
        }
    }
    .mascara {
        position: absolute;
        z-index: 2;
        display: table;
        width: 100%;
        height: 76px;
        bottom: 0;
        background-image: url($img + 'vitrine/borda.png');
        background-repeat: no-repeat;
        background-position: center top;
    }
}