.btn {
    &.btn-new {
        font-family: $fontDefault;
        color: #ffffff;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        border: none;
        border-radius: 0;
        @include bgMixColor();
    }
    &.btn-blue {
        position: relative;
        font-family: $fontDefault;
        background-color: $azul;
        border-radius: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        padding: 15px 20px;
        text-align: left;
        &.setaRight {
            &:before {
                content: "";
                position: absolute;
                background-image: url($img + 'geral/right.svg');
                background-repeat: no-repeat;
                background-position: center center;
                width: 20px;
                height: 20px;
                right: 20px;
                color: #ffffff;
            }
        }
    }
    &.btn-red {
        position: relative;
        font-family: $fontDefault;
        background-color: $vermelho;
        border-radius: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        padding: 15px 20px;
        text-align: left;
    }
}
