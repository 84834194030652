h1 {
    text-transform: uppercase;
    color: $azul;
    font-family: $fontDefault;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    margin: 0;
    @include breakpoint(768px, max) {
        font-size: 28px;
        line-height: 32px;
    }
}
.chamada {
    text-transform: uppercase;
    color: $azul;
    font-family: $fontDefault;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    @include breakpoint(768px, max) {
        font-size: 14px;
        line-height: 22px;
    }
}