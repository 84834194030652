.menu {
    text-align: right;
    margin-top: 20px;
    .botaoResponsivo {
        width: 100px;
        height: 50px;
        position: absolute;
        right: 0px;
        display: none;
        margin-top: 0;
        top: -7px;
        z-index: 9999;
        @include breakpoint(768px, max) {
            display: inline-block;
        }
        @include breakpoint('320px', 'max') {
            width: 75px;
        }
        .nomemr {
            float: left;
            color: $corBarraResponsivo;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 15px;
            margin-right: 10px;
            @include breakpoint('320px', 'max') {
                font-size: 10px;
            }
        }
        .linhasmr {
            float: right;
            margin-top: 8px;
            .b1 {
                top: 11px;
            }
            .b2 {
                top: 20px;
            }
            .b3 {
                top: 29px;
            }
            >span {
                background-color: $corBarraResponsivo;
                height: 4px;
                right: 0;
                position: absolute;
                top: 0;
                transition: all 0.3s ease 0s;
                width: 40px;
            }
            &.ativo {
                .b1 {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    top: 21px;
                    right: 0;
                }
                .b2 {
                    width: 0px;
                }
                .b3 {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    top: 21px;
                    right: 0;
                }
            }
        }
    }
    nav {
        display: inline-block;
        transition: all .4s ease-in-out;
        @include breakpoint(768px, max) {
            width: 100vw;
            display: block;
            position: absolute;
            z-index: 999;
            background-color: $azul;
            right: -120vw;
            top: -20px;
            height: 100vh;
        }
        ul {
            @include breakpoint(768px, max) {
                padding: 30px;
                margin: 80px 0 0;
                
                display: block;
                overflow: auto;
            }
            li {
                list-style-type: none;
                display: inline-block;
                @include breakpoint(768px, max) {
                    display: block;
                }
                a {
                    position: relative;
                    display: block;
                    color: #FFF;
                    font-family: $roboto;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: right;
                    text-transform: uppercase; 
                    margin-left: 50px;
                    margin-bottom: 10px;
                    font-weight: 700;
                    @include breakpoint(768px, max) {
                        display: block;
                        max-width: initial;
                        color: #ffffff;
                        font-size: 30px;
                        line-height: 40px;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        background-color: #fff;
                        height: 1px;
                        width: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: all .3s ease-in-out;
                        bottom: -3px;
                    }
                    &:hover {
                        text-decoration: none;
                        color: #FFF;
                        &:before {
                            width: 100%;
                        }
                    }
                }
                &.links {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                }
                &.login {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                        border-top: #fff 1px solid;
                        padding: 20px 0 30px;
                    }
                    form {
                        .col-12 {
                            margin-bottom: 8px;
                            .form-control {
                                height: 40px;
                            }
                            button {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        &.ativo {
            right: -20px;
        }
    }
}