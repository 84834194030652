.pagina {
    .texto {
        margin: 80px 0;
    }
    .fotos {
        margin: 80px 0;
        img {
            margin-bottom: 25px;
        }
    }
}