.contato {
    margin-bottom: 80px;
    .titulo {
        padding-top: 200px;
    }
    .dados {
        font-size: 22px;
        line-height: 24px;
        text-transform: uppercase;
        h2 {
            text-transform: none;
        }
        address {
            margin: 30px 0 20px;
        }
        .telefone {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .email {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    form {
        margin-bottom: 30px;
    }
}