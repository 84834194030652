/*!
Theme Name: Varney
Theme URI: http://www.agenciadominio.com.br/
Description: Site desenvolvido especificamente para uso da Varney;
Author: Ag&ecirc;ncia Dom&iacute;nio
Author URI: http://www.agenciadominio.com.br/
Version: 1.0
*/

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/fancybox";


//Components
@import "components/loader";
@import "components/compartilhe";
@import "components/titulo";
@import "components/whatsapp";
@import "components/buttons";
@import "components/select";
@import "components/inputs";
@import "components/h1";
@import "components/paginacao";

//Layout Site
@import "header";
@import "menu";
@import "vitrine";
@import "carroIdeal";
@import "carros";
@import "sobre";
@import "banners";
@import "redes";
@import "veiculo";
@import "contato";
@import "pagina";
@import "footer";