.carros {
    margin-top: 40px;
    margin-bottom: 20px;
    .col-12 {
        position: relative;
    }
    a {
        display: block;
        width: 100%;
        position: relative;
        .carro {
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            align-items: flex-end;
            min-height: initial;
            margin-bottom: 25px;
            @include breakpoint(768px, max) {
                display: block;
                min-height: initial;
            }
            .foto {
                position: relative;
                z-index: 1;
                width: 100%;
                figure {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: auto;
                    overflow: hidden;
                    z-index: 1;
                    margin: 0;
                    img {
                        transition: all 0.3s ease;
                        height: auto;
                    }
                }
            }
            .dados {
                width: 100%;
                z-index: 2;
                position: relative;
                display: block;
                .nome {
                    background-color: rgba(#000000, 0.6);
                    padding: 10px 15px;
                    h2 {
                        display: block;
                        color: #ffffff;
                        text-transform: uppercase;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
                .padList {
                    margin: 0;
                }
                .valor {
                    display: block;
                    background-color: $vermelho;
                    padding: 15px 20px;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                }
                .whatsapp {
                    background-color: #2a5f55;
                    padding: 9px 13px;
                    color: #ffffff;
                    display: block;
                    text-align: center;
                    .icone {
                        display: inline-block;
                        margin-right: 6px;
                        i {
                            font-size: 15px;
                            margin-top: 3px;
                            @include breakpoint(768px, max) {
                                font-size: 24px;
                            }
                        }
                    }
                    .text {
                        display: inline-block;
                        max-width: 80%;
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 600;
                        @include breakpoint(768px, max) {
                            font-size: 14px;
                            line-height: 18px;
                            max-width: 80%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            &.offers {
                .dados {
                    .whatsapp {
                        padding: 12px 13px;
                        .icone {
                            i {
                                font-size: 22px;
                                margin-top: 0;
                            }
                        }
                        .text {
                            font-size: 10px;
                            line-height: 12px;
                            text-align: left;
                        }
                    }
                }
            }
        }
        &:hover {
            text-decoration: none;
            .carro {
                .foto {
                    figure {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .dados {
                    .nome {
                        background-color: $azul;
                    }
                }
            }
        }
    }
}
