footer {
    background-color: #fff;
    padding: 0;
    .logo {
        @include breakpoint(768px, max) {
            display: table;
            margin: auto auto 30px;
        }
    }
    .dados {
        padding: 40px 0;
        display: block;
        a {
            @include breakpoint(768px, max) {
                display: table;
                margin: 5px auto;
            }
            &.ligue {
                @include breakpoint(768px, max) {
                    margin-top: 30px;
                }
                .icone {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                }
                .texto {
                    display: inline-block;
                    vertical-align: middle;
                    .linha1 {
                        text-transform: uppercase;
                        color: $azul;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    .linha2 {
                        text-transform: uppercase;
                        color: $azul;
                        font-size: 32px;
                        line-height: 38px;
                        font-weight: 600;
                    }
                }
            }
            &:hover {
                text-decoration: none;
            }
        }
        .info {
            text-align: right;
            @include breakpoint(768px, max) {
                display: table;
                margin: auto;
            }
            a {
                .icone {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                }
                .texto {
                    display: inline-block;
                    vertical-align: middle;
                    .linha1 {
                        text-transform: uppercase;
                        color: $azul;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    .linha2 {
                        text-transform: uppercase;
                        color: $azul;
                        font-size: 32px;
                        line-height: 38px;
                        font-weight: 600;
                    }
                }
                &.desktop {
                    display: block;
                    @include breakpoint(768px, max) {
                        display: none;
                    }
                }
                &.mobile {
                    display: none;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                }
            }
        }
    }
    .tarja {
        display: table;
        width: 100%;
        height: auto;
        background-color: $azul;
        padding: 38px 0;
        address {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
            @include breakpoint(768px, max) {
                text-align: center;
                margin-bottom: 10px;
            }
        }
        a {
            &.mail {
                display: block;
                text-align: center;
                color: #ffffff;
                font-size: 20px;
                line-height: 24px;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .social {
            ul {
                margin: 0;
                text-align: right;
                @include breakpoint(768px, max) {
                    margin: 20px auto 0;
                    text-align: center;
                }
                li {
                    display: inline-block;
                    list-style-type: none;
                    a {
                        display: table;
                        margin-left: 10px;
                        i {
                            font-size: 40px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .copyright {
        display: block;
        padding: 30px 0;
        font-size: 12px;
        line-height: 16px;
        color: $azul;
        @include breakpoint(768px, max) {
            text-align: center;
        }
        a {
            display: block;
            color: $azul;
            text-align: right;
            @include breakpoint(768px, max) {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
}
